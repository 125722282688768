import route from './route';

export default {
  route,
  components:{
    thisBaseOn: 'Основан на',
    baseOnL18n: 'Интернационализация этого проекта основана на',
    baseOn: 'основан на',
    documentation: 'Документация',
    palette: 'Свою палитру можно сгененрировать на',
    theme: 'Выберите цветовую схему:',
    dropHere: 'Для загрузки перетащите файл сюда',
    tinymceTips: 'Онлайновый текстовый редактор, выпущенный в виде программного обеспечения '
      + 'с открытым исходным кодом под LGPL.',
    imageUploadTips: 'Если собираетесь использовать, то лучше проверить официальную версию.',
    dropzoneTips: '',
    stickyTips: 'при прокрутке странице будет закреплен вверху',
    backToTop: 'Когда страница прокручивается до указанной позиции, ' +
      'в правом нижнем углу появляется кнопка «Вверх»',
    draggable: 'Позволяет перетаскивать и панели и элементы внутри',
  },
  toolbar: {
    appname: 'Приложение',
    settings: 'Настройки',
    profile: 'Профиль',
    logout: 'Выйти',
  },
  settings: {
    title: 'Внешний Вид',
    default: 'По умолчанию',
    version: 'Версия',
    position: 'Положение елементов',
    theme: 'Цветовая схема',
    dark: 'Тёмная тема',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  login: {
    titleIn: 'Форма авторизации',
    titleUn: 'Форма регистрации',
    singIn: 'Войти',
    singUp: 'Зарегать', // "Зарегистрироваться" - верстка плывет, если не убрать кнопки
    email: 'Эл. почта',
    password: 'Пароль',
    confirm: 'Подтвердить',
  },
  errors: {
    whoops: 'Упс',
    back: 'Вернуться!',
    301: 'Перемещён на постоянной основе',
    401: 'Неавторизовано',
    403: 'Запрещено',
    404: 'Не найден',
    500: 'Внутренняя ошибка сервера',
  },
  guide: {
    description: 'Страница руководства полезна для людей, которые впервые знакомятся с проектом.'
      + ' Вы можете кратко представить особенности проекта. Демо основано на',
    button: 'Показать руководство',
  },
  ui: {
    switch: 'Сменить язык',
    theme: 'Сменить тему',
    success: 'Успех',
    error: 'Ошибка',
    warning: 'Предупреждение',
    info: 'Информация',
    primary: 'Основной',
    secondary: 'Вторичный',
    default: 'Стандартный',
    accent: 'Акцент',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Электронная почта',
  },
};
