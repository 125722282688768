const state = {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
  drawer: null,
  themeColor:'#4CAF50'
}

const mutations = {
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_THEME_COLOR (state, payload) {
    state.themeColor = payload
  }
}

const actions = {

}

export default {
  namespace: true,
  state,
  actions,
  mutations,
}
