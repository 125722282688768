import route from './route';

export default {
  route,
  Inactive:'不活跃的',
  search:'搜索',
  cancel:'取消',
  components: {
    thisBaseOn: '这是基于',
    baseOnL18n: '该项目的国际化基于',
    baseOn: '是根据',
    documentation: '文献资料',
    palette: '您可以在上生成调色板',
    theme: '选择配色方案：',
    dropHere: '将文件拖放到此处进行上传',
    tinymceTips: '在线富文本编辑器作为LGPL下的开源软件发布。',
    imageUploadTips: '改性。 如果要使用它，最好使用正式版本。',
    dropzoneTips: '',
    stickyTips: '当页面滚动到预设位置时，将在顶部粘贴。',
    backToTop: '当页面滚动到指定位置时，“返回顶部”按钮将显示在右下角',
    draggable: '允许您在其中拖动面板和项目',
  },
  toolbar: {
    appname: '我的应用',
    settings: '设置',
    profile: '资料',
    logout: '退出',
  },
  settings: {
    title: '主题设置',
    default: '设为默认',
    version: '版本',
    position: '元素位置',
    theme: '主题',
    dark: '暗黑主题',
    toolbarDense: '紧凑工具栏',
    navbarDense: '紧凑导航栏',
    navbarLogo: '导航栏Logo',
    settingsBtn: '设置按钮',
    footer: '页脚',
  },
  dashboard:{
    Refresh:'刷新',
    dashboard:'仪表板',
    Status:'状态',
    ClusterStatus:'集群状态',
    Hosts:'主机',
    Monitors:'监视器',
    Managers:'管理器',
    ObjectGateways:'对象网关',
    MetadataServers:'元数据服务器',
    iSCSIGateways:'iSCSI 网关',
    Capacity:'容量',
    RawCapacity:'原始容量',
    Objects:'对象',
    PGStatus:'PG状态',
    Pools:'池',
    PGsperOSD:'PGs per OSD',
    Performance:'性能',
    ClientRead_Write:'客户端读/写',
    ClientThroughput:'客户端吞吐量',
    RecoveryThroughput:'恢复吞吐量',
    Scrubbing:'数据清理',
    more:'更多'
  },
  user:{
    oldPassword:'旧密码',
    newPassword:'新密码',
    confirmNewPassword:'确认新密码',
    changePassword:'更改密码'
  },
  login: {
    titleIn: '登录',
    titleUn: '注册',
    singIn: '登录',
    singUp: '注册',
    email: 'Email',
    password: '密码',
    confirm: '确认',
  },
  errors: {
    whoops: '哦嚯~!',
    back: '返回!',
    301: 'Moved Permanently',
    401: '未授权访问',
    403: '禁止访问',
    404: '页面未找到',
    500: '内部服务器错误',
  },
  guide: {
    description: '引导页对于第一次进入项目的人来说是很有用的. 你可以简单的介绍一下项目的特性. Demo是基于',
    button: '显示引导信息',
  },
  ui: {
    switch: '切换语言',
    theme: '切换主题',
    success: '成功',
    error: '错误',
    warning: '警告',
    info: '提示',
    primary: '主要',
    secondary: '次要',
    default: '默认',
    accent: '强调',
    firstName: '名',
    lastName: '姓',
    email: 'E-mail',
  },
};
