const state = {
  newsList: {}
}
const mutations = {
  setNewList(state, payload) {
    state.newsList = payload
  },
}
export default {
  namespaced: true,
  state,
  mutations,
}