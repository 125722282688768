<template>
  <router-view  v-resize="onResize"/>
</template>

<script>
  export default {
    name: 'App',
    methods: {
      onResize () {
        var pageWidth = window.innerWidth;
        if(typeof pageWidth != "number"){ 
          if(document.compatMode == "number"){ 
            pageWidth = document.documentElement.clientWidth;
          }else{ 
            pageWidth = document.body.clientWidth; 
          } 
        }
        var fontSize = (window.innerWidth * 100) / 1920;
        if(fontSize<40){
          fontSize = 40;
        }
        let grid;
        if(window.innerWidth>1904){
          grid = 'xl'
          // this.reload()
        }else if(1264>window.innerWidth<1904){
          grid = 'lg'
          // this.reload()
        }else if(960>window.innerWidth<1264){
          grid = 'md'
          // this.reload()
        }

        window.localStorage.setItem('rem-font',fontSize);
        window.localStorage.setItem('grid',grid);
        //根据屏幕大小确定根节点字号
        document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px';
      },
    },
  }
</script>
