import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: "/",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [{
    path: "/",
    component: () => import("@/views/page/Page"),
    redirect: "home",
    children: [{
        name: "Home",
        path: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        name: "Position",
        path: "position/:id",
        component: () => import("@/views/Position.vue"),
      },
      {
        name: "News",
        path: "news",
        component: () => import("@/views/News.vue"),
      },
      {
        name: "NewsContent",
        path: "news/:id",
        component: () => import("@/views/NewsContent.vue"),
      },
      {
        name: "contactUs",
        path: "contact-us",
        component: () => import("@/views/contact-us.vue"),
      },
      {
        name: "privacyPolicy",
        path: "/privacy-policy",
        component: () => import("@/views/privacyPolicy.vue"),
      },
      {
        name: "Catalytic",
        path: "catalytic",
        component: () => import("@/views/Catalytic.vue"),
      },
      {
        name: "Drug",
        path: "drug",
        component: () => import("@/views/Drug.vue"),
      },
      {
        name: "AboutUs",
        path: "aboutus",
        component: () => import("@/views/AboutUs.vue"),
      },
      {
        name: "JoinUs",
        path: "joinus",
        component: () => import("@/views/JoinUs.vue"),
				children: [
					{
						path: '',
						redirect: '/joinus/list',
					},
					{
						path: 'list',
						component: () => import("@/views/JoinUsList.vue")
					},
					{
						path: 'details/:id',
						component: () => import("@/views/JoinUsDetails.vue")
					},
				]
      },
    ],
  }, ],
});
