import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";

import "@/assets/font/font.face.css";
import "@/assets/iconfont/iconfont.css";
import "normalize.css/normalize.css";
import "vuetify/dist/vuetify.min.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@/assets/styles/index.scss";

import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./locales";
Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
