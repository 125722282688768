import route from './route';

export default {
  route,
  Inactive:'Inactive',
  cancel:'cancel',
  components: {
    thisBaseOn: 'This is based on',
    baseOnL18n: 'The internationalization of this project is based on',
    baseOn: 'is based on',
    documentation: 'Documentation',
    palette: 'You can generate your palette on',
    theme: 'Choose a color scheme:',
    dropHere: 'Drop files here to upload',
    tinymceTips: 'Online rich-text editor released as open-source software under the LGPL.',
    imageUploadTips: 'Modified. If you are going to use it, it is better to use official version.',
    dropzoneTips: '',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTop: 'When the page is scrolled to the specified position, ' +
      'the Back to Top button appears in the lower right corner',
    draggable: 'Allows you to drag and panel and items inside',
  },
  toolbar: {
    appname: 'Application',
    settings: 'Settings',
    profile: 'Profile',
    logout: 'Logout',
  },
  settings: {
    title: 'Theme Settings',
    default: 'Set Default',
    version: 'Version',
    position: 'Position of elements',
    theme: 'Theme',
    dark: 'Dark theme',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  login: {
    titleIn: 'Sing In Form',
    titleUn: 'Sing Un Form',
    singIn: 'Sing in',
    singUp: 'Sing up',
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm',
  },
  dashboard:{
    Refresh:'Refresh',
    dashboard:'dashboard',
    Status:'Status',
    ClusterStatus:'Cluster Status',
    Hosts:'Hosts',
    Monitors:'Monitors',
    Managers:'Managers',
    ObjectGateways:'Object Gateways',
    MetadataServers:'Metadata Servers',
    iSCSIGateways:'iSCSI Gateways',
    Capacity:'Capacity',
    RawCapacity:'Raw Capacity',
    Objects:'Objects',
    PGStatus:'PG Status',
    Pools:'Pools',
    PGsperOSD:'PGs per OSD',
    Performance:'Performance',
    ClientRead_Write:'Client Read/Write',
    ClientThroughput:'Client Throughput',
    RecoveryThroughput:'Recovery Throughput',
    Scrubbing:'Scrubbing',
    more:'more'
  },
  user:{
    oldPassword:'Old password',
    newPassword:'New password',
    confirmNewPassword:'Confirm new password',
    changePassword:'Change password'
  },
  errors: {
    whoops: 'Whoops',
    back: 'Get me out of here!',
    301: 'Moved Permanently',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for '
      + 'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide',
  },
  ui: {
    switch: 'Switch Language',
    theme: 'Switch Theme',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    primary: 'Primary',
    secondary: 'Secondary',
    default: 'Default',
    accent: 'Accent',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
  },
};
